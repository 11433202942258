<template>
  <b-modal ref="CreateCompany" size="xl" scrollable hide-header-close no-close-on-backdrop no-close-on-esc>
    <template #modal-title>
      <h5 class="modal-title" id="CreateCompanyLabel">
        <ph-plus :size="18"/>
        {{ $t('company.new_company') }}
      </h5>
    </template>
    <form class="mx-form mx-modal-form">
      <div class="mb-3">
        <div class="mx-modal-form__element" v-for="element in form_elements">
          <label class="form-label">{{ $t(`company.${element}`) }}</label>
          <input type="text" class="form-control" v-model="form[element]">
          <div v-if="errors[element]" class="invalid-feedback">
            {{ $t(`company.errors.${getErrorWord(errors[element][0]) }`)}}
          </div>
        </div>
      </div>

<!--      <span class="mx-modal-tr"></span>-->

      <div class="mb-3 mx-hour-wrapper">
       <div>
         <h4 class="mx-modal-tr__title">{{ $t(`company.opening_hours`) }}</h4>
         <div class="mx-hour" v-for="element in hour_elements">
           <strong class="mx-hour__title">{{ $t(`company.day_of_week${mobile ? '_short' : ''}.${element}`) }}</strong>
           {{element.closed}}
           <div class="mx-hour__inputs" v-show="form.opening_hours[element].open === '1'">
             <input type="text" class="form-control mx-hour__inputs-input" v-mask="'99:99'" placeholder="08:30" v-model="form.opening_hours[element].from">
             <span class="mx-hour__inputs-tr"> - </span>
             <input type="text" class="form-control mx-hour__inputs-input" v-mask="'99:99'" placeholder="19:30" v-model="form.opening_hours[element].to">
           </div>
           <div class="mx-hour__inputs"v-show="form.opening_hours[element].open === '0'">
             <span class="mx-date-day_of_week__time-closed">{{ $t('company.closed') }}</span>
           </div>
           <span @click="form.opening_hours[element].open = closedDay(form.opening_hours[element].open)" class="mx-hour__close">
             <ph-lock-laminated :size="18" />
           </span>
         </div>
       </div>
      </div>

    </form>
    <template slot="modal-footer" slot-scope="{ hide }">
      <button :disabled="button_disabled" @click="close" type="button" class="btn btn-peepz-default">{{ $t('company.abort') }}</button>
      <button :disabled="button_disabled" @click="save" type="button" class="btn btn-peepz-success">{{ $t('company.save') }}</button>
    </template>
  </b-modal>
</template>

<script>
import {PhPlus, PhLockLaminated} from "phosphor-vue";

export default {
  components: {PhPlus,PhLockLaminated},
  data() {
    return {
      button_disabled: false,
      hour_elements: ['mo','di','mi','do','fr','sa','so'],
      hour: {
        mo: {from: '', to: '', open: true},
        di: {from: '', to: '', open: true},
        mi: {from: '', to: '', open: true},
        do: {from: '', to: '', open: false},
        fr: {from: '', to: '', open: true},
        sa: {from: '', to: '', open: true},
        so: {from: '', to: '', open: true}
      },

      form_elements: [
        'company_name',
        'company_name_short',
        'country',
        'city',
        'postcode',
        'street',
        'company_phone',
      ],
      mobile: false,
      form: {
        company_name: '',
        company_phone: '',
        company_name_short: '',
        country: '',
        city: '',
        postcode: '',
        street: '',
        is_main: 1,
        opening_hours: {
          mo: {from: 'null', to: 'null', open: '1'},
          di: {from: 'null', to: 'null', open: '1'},
          mi: {from: 'null', to: 'null', open: '1'},
          do: {from: 'null', to: 'null', open: '1'},
          fr: {from: 'null', to: 'null', open: '1'},
          sa: {from: 'null', to: 'null', open: '0'},
          so: {from: 'null', to: 'null', open: '0'}
        }
      },
      errors: [],
    }
  },
  mounted() {
    this.mobile = window.innerWidth <= '768'
    this.createCompany()
  },
  methods: {
    closedDay(bool) {
      return bool === '1'? '0': '1'
    },

    close() {
      this.hideModal()
    },

    hideModal() {
      this.$emit('close')
      this.$refs['CreateCompany'].hide()
    },

    createCompany() {
      this.$refs['CreateCompany'].show()
    },
    save() {
      this.button_disabled = true;
      this.$store.dispatch('companyCreate', this.form).then(() => {
        this.hideModal()
      }).catch(error => {
        this.button_disabled = false
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data;
            break;
        }
      })
    },
    getErrorWord(str) {
      return str.match(/\b(required|number)\b/g)[0]
    }
  }
}
</script>
