<template>
  <CabinetPage :preloader="preloader">
    <div class="mx-cabinet-page-header">
      <h4 class="mx-title">{{ $t('company.companies') }}</h4>
      <div v-if="options.multi_company || user.multi_company === 1 || companies.length === 0" class="mx-actions">
        <button type="button" @click="createCompany" class="btn btn-peepz-success" data-bs-toggle="modal" data-bs-target="#CreateCompany">
          <ph-plus-circle :size="22" />
          <span>{{ $t('company.new_company') }}</span>
        </button>
      </div>
    </div>

    <div style="display: flex; flex-direction: column; min-height: calc(100% - 100px);">
      <div class="row">
        <div class="col-md-12">
          <div v-if="companies.length > 0" class="row">
            <div v-for="company in companies" :key="company.id" class="mx-list_card">
              <router-link :to="{name: 'company.show', params: {id: company.id}}" class="mx-card">
                <h5 class="mx-title_border">
                  <ph-buildings :size="24" />
                  <span>{{ company.company_name }} <strong>({{ company.company_name_short }})</strong></span>
                </h5>
                <div class="mx-list_element-info">
                  <strong>{{ $t('company.main_address') }}</strong>
                  <div v-for="address in company.addresses" v-if="parseInt(address.is_main) === 1">
                    <span>{{ company.company_name }}<br>{{ address.street }}<br>{{ address.postcode }} {{ address.city }}<br>{{ address.country }}</span>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
          <div v-else class="row">
            <div class="mx-card">
              <strong class="mx-notfound">
                {{ $t('company.companies_not_found') }}
              </strong>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-auto">
        <ContactPersonList />
      </div>
    </div>

    <CreateCompany v-if="create_company" @close="createCompanyClose"/>

  </CabinetPage>
</template>

<script>

import { PhBuildings, PhIdentificationCard, PhPlus, PhPlusCircle, PhDotsThreeVertical  } from "phosphor-vue";
import CabinetPage from "@/components/main/CabinetPage";
import CreateCompany from "@/components/company/CreateCompany";
import ContactPersonList from "../../../components/contact_person/ContactPersonList";

export default {
  components: {CreateCompany, CabinetPage, PhBuildings, PhIdentificationCard, PhPlus, PhPlusCircle, PhDotsThreeVertical, ContactPersonList},
  data() {
    return {
      preloader: true,
      companies: [],
      create_company: false,
      user: {},

    }
  },
  mounted() {
    this.user = this.$store.getters.user

    this.$store.dispatch('companies').then(() => {
      this.companies = this.$store.getters.companies;
      setTimeout(this.preload, this.options.preload_time);
    })
  },
  methods: {
    createCompanyClose() {
      this.create_company = false
    },
    createCompany() {
      this.create_company = true
    },
    preload() {
      this.preloader = false;
    },
  }
}
</script>
